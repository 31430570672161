import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import AdminDomainsExplorer from "./AdminDomainsExplorer";
import AdminSecretsExplorer from "./AdminSecretsExplorer";
import AdminSiftExplorer from "./AdminSiftExplorer";
import AdminUserExplorer from "./AdminUserExplorer";
import AdminOrganizationsExplorer from "./AdminOrganizationsExplorer";
import Sift from "./Sift";
import AdminSubscriptionsExplorer from "./AdminSubscriptionsExplorer";
import AdminPulseProvisioner from "./AdminPulseProvisioner";
import AdminPendingReplaySiftsExplorer from "./AdminPendingReplaySiftsExplorer";
import AdminOnDMARCSubscriptionsExplorer from "./AdminOnDMARCSubscriptionsExplorer";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AdminInterface = ({ user }: { user: any }) => {
  const [tab, setTab] = useState(0);
  const [adminSift, setAdminSift] = useState<undefined | any>(undefined);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };
  return (
    <div
      style={{
        width: "calc(100vw - 67px)",
        minHeight: "100vh",
        display: "flex",
        alignItems: "stretch",
      }}
    >
      {!adminSift && (
        <Paper style={{ flex: 1, padding: 10, margin: 10 }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "16px",
            }}
          >
            <AdminPanelSettingsIcon fontSize="large" />
            <Typography style={{ marginLeft: "8px" }} variant="h5">
              Red Sift Admin Console
            </Typography>
          </div>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={tab}
                onChange={handleTabChange}
                aria-label="Explorer selector"
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
              >
                <Tab label="Product Explorer" {...a11yProps(0)} />
                <Tab label="User Explorer" {...a11yProps(1)} />
                <Tab label="Domains Explorer" {...a11yProps(2)} />
                <Tab label="Organizations Explorer" {...a11yProps(3)} />
                <Tab label="Subscriptions Explorer" {...a11yProps(4)} />
                <Tab label="Secrets Explorer" {...a11yProps(5)} />
                <Tab label="Pulse Provisioner" {...a11yProps(6)} />
                <Tab label="Replay Sift Installations" {...a11yProps(7)} />
                <Tab label="OnDMARC Subscriptions Explorer" {...a11yProps(8)} />
              </Tabs>
            </Box>
            <TabPanel value={tab} index={0}>
              <AdminSiftExplorer />
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <AdminUserExplorer />
            </TabPanel>
            <TabPanel value={tab} index={2}>
              <AdminDomainsExplorer />
            </TabPanel>
            <TabPanel value={tab} index={3}>
              <AdminOrganizationsExplorer />
            </TabPanel>
            <TabPanel value={tab} index={4}>
              <AdminSubscriptionsExplorer />
            </TabPanel>
            <TabPanel value={tab} index={5}>
              <AdminSecretsExplorer />
            </TabPanel>
            <TabPanel value={tab} index={6}>
              <AdminPulseProvisioner />
            </TabPanel>
            <TabPanel value={tab} index={7}>
              <AdminPendingReplaySiftsExplorer />
            </TabPanel>
            <TabPanel value={tab} index={8}>
              <AdminOnDMARCSubscriptionsExplorer
              />
            </TabPanel>
          </Box>
        </Paper>
      )}
      {!!adminSift && (
        <Sift user={user} installed={adminSift.sift} siftJwt={adminSift.jwt} />
      )}
    </div>
  );
};

export default AdminInterface;
