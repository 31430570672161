import { useContext, useMemo, useState } from "react";
import { Button, ButtonLink } from "@redsift/design-system";
import log from "loglevel";

import { getIam, getSalesForceSearchUrl } from "../libs/utils";
import AppContext from "./AppContext";
import SearchableTable from "./SearchableTable";
import TooltipCell from "./TooltipCell";
import { GridValueGetterParams } from "@mui/x-data-grid-pro";
import { ALL_SIFTS } from "../libs/constants";
import dayjs from "dayjs";

const ONDMARC_GUID = "Qvpg7LTaTMw1-Xfz1puIhH1sgs7IjV6mGb1g20XQ-5DDhyxJ.1";

const getLastLoginFromListOfUsers = (users: { lastLogin: string }[]) => {
  if (!users || users.length === 0) {
    return null;
  }
  return new Date(
    [...users].sort(
      (a, b) =>
        (new Date(a?.lastLogin)?.getTime() || 0) -
        (new Date(b?.lastLogin)?.getTime() || 0)
    )[users.length - 1]?.lastLogin
  );
};

const getSubscriptions = async (ctx: any, setOrganizations: Function) => {
  const resp = await getIam(
    `/api/admin/legacy-subscriptions?guid=${ONDMARC_GUID}`
  );
  if (resp.error) {
    log.error("getOrganizations::", resp);
    ctx.setAlert(resp.error, "error");
  } else {
    if (resp.length === 0) {
      ctx.setAlert("No entries found", "info");
    }
    setOrganizations(resp);
  }
};

const AdminOnDMARCSubscriptionsExplorer = () => {
  const ctx = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);

  const columns = useMemo(
    () => [
      {
        field: "id",
        headerName: "Sift ID",
        flex: 1,
        renderCell: ({ value }: any) => <TooltipCell value={value} />,
      },
      {
        field: "metadata.name",
        headerName: "Name",
        flex: 1,
        valueGetter: ({ row }: any) => row.metadata?.name,
        renderCell: ({ value }: any) => {
          return <TooltipCell value={value} />;
        },
      },
      {
        field: "metadata.description",
        headerName: "Description",
        flex: 1,
        valueGetter: ({ row }: any) => row.metadata?.description,
        renderCell: ({ value }: any) => <TooltipCell value={value} />,
      },
      {
        field: "metadata.userCount",
        headerName: "User Count",
        flex: 1,
        valueGetter: ({ row }: any) => row.metadata?.description,
        renderCell: ({ value }: any) => <TooltipCell value={value} />,
      },
      {
        field: "createdAt",
        headerName: "Created Date",
        flex: 1,
        valueGetter: ({ row }: any) => {
          if (!row?.createdAt) {
            return "No Date available";
          } else {
            return new Date(row?.createdAt).toString();
          }
        },
        renderCell: ({ value }: any) => <TooltipCell value={value} />,
      },
      {
        field: "users",
        headerName: "Users",
        flex: 1,
        valueGetter: ({ value }: GridValueGetterParams) => {
          return value
            ?.map((v: any) => `${v.email}:${v.lastLogin}`)
            .join(" | ");
        },
        renderCell: ({ value }: any) => <TooltipCell value={value} />,
      },
      {
        field: "subscription.legacy",
        headerName: "Subscription",
        flex: 1,
        valueGetter: ({ row }: any) => JSON.stringify(row.subscription?.legacy),
        renderCell: ({ value }: any) => <TooltipCell value={value} />,
      },
      {
        field: "Trial end date",
        headerName: "Trial end date",
        type: "dateTime",
        valueGetter: ({ row }: any) => {
          try {
            return row?.subscription?.legacy?.configuration &&
              JSON.parse(row?.subscription?.legacy?.configuration).length
              ? new Date(
                  JSON.parse(
                    row?.subscription?.legacy?.configuration
                  )[0]?.access?.end
                )
              : null;
          } catch (error) {
            log.error("Failed to parse configuration JSON", error, row.id);
            return null;
          }
        },
        renderCell: ({ value }: any) => (
          <TooltipCell
            value={
              value ? dayjs(value).format("YYYY-MM-DD") : "No date available"
            }
          />
        ),
      },
      {
        field: "Latest Log in date",
        headerName: "Latest log in date",
        type: "dateTime",
        valueGetter: ({ row }: any) => {
          return getLastLoginFromListOfUsers(row?.users);
        },
        renderCell: ({ value }: any) => (
          <TooltipCell
            value={
              value ? dayjs(value).format("YYYY-MM-DD") : "No date available"
            }
          />
        ),
      },
      {
        field: "JSON Malformed",
        headerName: "JSON Malformed",
        type: "boolean",
        valueGetter: ({ row }: any) => {
          try {
            JSON.parse(row?.subscription?.legacy?.configuration)[0];
            return false;
          } catch (error) {
            return true;
          }
        },
        renderCell: ({ value }: any) => (
          <TooltipCell value={Boolean(value).toString()} />
        ),
      },
      {
        field: "searchSf",
        headerName: "Search Salesforce",
        renderCell: (row: any) => {
          const salesForceUrl = getSalesForceSearchUrl(row.id);
          return (
            <ButtonLink
              href={`${salesForceUrl}`}
              target="_blank"
              rel="noreferrer"
            >
              Search
            </ButtonLink>
          );
        },
      },
      {
        field: "manage",
        headerName: "Manage",
        renderCell: ({ row }: any) =>
          ALL_SIFTS[ONDMARC_GUID].canAdmin && (
            <ButtonLink
              isDisabled={Boolean(row.deletedAt)}
              href={`/sift/${ONDMARC_GUID}/${row.id}`}
            >
              Manage
            </ButtonLink>
          ),
      },
    ],
    [ctx]
  );
  return (
    <div
      style={{
        display: "flex",
        flexFlow: "wrap",
        justifyContent: "space-between",
      }}
    >
      <form
        style={{ display: "flex", flexFlow: "wrap" }}
        onSubmit={async (ev) => {
          setLoading(true);
          ev.preventDefault();
          await getSubscriptions(ctx, setSubscriptions);
          setLoading(false);
        }}
      >
        <Button
          isLoading={loading}
          color="primary"
          type="submit"
          style={{ margin: "5px 0px 0px 5px" }}
        >
          Load Subscriptions
        </Button>
      </form>

      <SearchableTable
        tableId="subscriptions-explorer"
        columns={columns}
        rows={subscriptions}
        style={{ marginTop: 20 }}
        initialState={{
          columns: {
            columnVisibilityModel: {},
          },
        }}
      />
    </div>
  );
};

export default AdminOnDMARCSubscriptionsExplorer;
