import log from "loglevel";
import router from "next/router";
import * as gtag from "./analytics/gtag";
import { ALL_SIFTS, ANALYTICS_PARAMETERS, IAM_URL } from "./constants";

// NOTE: this is only to be used by sifts as it expects the location to be under /sift/guid/instance
export const getRouteLocationForSift = (location = window.location) => {
  const {
    hash,
    host,
    hostname,
    href,
    origin,
    pathname,
    port,
    protocol,
    search,
  } = location;
  let pname = "/" + pathname.split("/").slice(4).join("/");

  log.debug("getRouteLocationForSift::pname:", pname);

  return {
    hash,
    host,
    hostname,
    href,
    origin,
    pathname: pname,
    port,
    protocol,
    search,
  };
};

export const getSiftInfoFromPath = (
  user?: any,
  location = window.location
):
  | {
      guid: string | null | undefined;
      instance: string | null | undefined;
      pathname: string;
      search: string;
      hash: string;
      host: string;
      hostname: string;
      href: string;
      origin: string;
      port: string;
      protocol: string;
    }
  | {
      guid: null;
      instance: null;
    } => {
  log.debug("getSiftInfoFromPath::data:", user?.sifts);
  const { pathname } = location;
  if (pathname.startsWith("/sift")) {
    const parts = pathname.split("/");
    log.debug("getSiftInfoFromPath::parts:", parts);
    // A valid path is of the format /sift/<guid>/<instance>
    if (parts.length >= 3) {
      // It seems to contain a guid
      const guid = parts[2];
      let instance;
      if (parts.length >= 4) {
        // It seems to contain an instance
        instance = parts[3];
      }
      log.debug("getSiftInfoFromPath::instance:", instance);
      const dataSifts = user?.sifts || [];
      const sifts: any = {};
      for (const s of dataSifts) {
        if (!sifts[s.guid]) {
          sifts[s.guid] = [];
        }
        sifts[s.guid].push(s);
      }
      if (sifts && sifts[guid]) {
        // User has access to this GUID
        for (const inst of sifts[guid]) {
          log.debug("getSiftInfoFromPath::", guid, inst);
          if (inst.id === instance) {
            // User has access to this instance, return both
            return {
              guid,
              instance,
              ...location,
              pathname: "/" + parts.slice(4).join("/"),
            };
          }
        }
        if (user?.attributes?.isCustSupport) {
          // Cust support can access all instances
          return {
            guid,
            instance: instance,
            ...location,
            pathname: "/" + parts.slice(instance ? 4 : 3).join("/"),
          };
        }
        // If only matched on the GUID, return guid
        return {
          guid,
          instance: null,
          ...location,
          pathname: "/" + parts.slice(3).join("/"),
        };
      } else if (ALL_SIFTS[guid]) {
        if (user?.attributes?.isCustSupport) {
          // Cust support can access all instances
          return {
            guid,
            instance: instance,
            ...location,
            pathname: "/" + parts.slice(instance ? 4 : 3).join("/"),
          };
        }
        return {
          guid,
          instance: null,
          ...location,
          pathname: "/" + parts.slice(3).join("/"),
        };
      }
    }
  }
  // Nothing matched
  return { guid: null, instance: null };
};

export const modifySiftUrl = (
  state: { guid: string; instance: string },
  action: string,
  location: any = {}
) => {
  const { pathname, search, hash } = location;
  log.debug("modifySiftUrl::pathname:", pathname, state);

  const path =
    "/sift/" +
    (state.guid || "") +
    (state.instance ? "/" + state.instance : "") +
    (pathname || "") +
    (search || "") +
    (hash || "");
  log.debug("modifySiftUrl::path:", path);
  // Send GTAG page view
  gtag.pageview({
    page_path: pathname || "/",
    page_title: state.guid
      ? ALL_SIFTS[state.guid]
        ? ALL_SIFTS[state.guid].name
        : state.guid
      : "Red Sift",
  });
  if (action.toLowerCase() === "push") {
    window.history.pushState({}, "", decodeURIComponent(path));
  } else if (action.toLowerCase() === "replace") {
    window.history.replaceState({}, "", decodeURIComponent(path));
  } else {
    log.error("sift-utils::modifySiftUrl: unexpected action", action);
  }
};

export const installSift = async (
  guid: string,
  metadata?: {},
  queryParams: { checkUserDomain?: string; waitingRoom?: string } = {}
) => {
  log.debug("utils::installSift", guid);
  const { query } = router;
  const {
    _ga = "",
    product = "",
    utm_source = "",
    utm_content = "",
    utm_medium = "",
    utm_campaign = "",
    utm_keyword: utm_term = "",
    gclid = "",
  } = query;
  const tracking = {
    _ga,
    product,
    utm_source,
    utm_content,
    utm_medium,
    utm_campaign,
    utm_term,
    gclid,
  };
  const { id, found, awaitingProvisioning } = await fetch(
    IAM_URL + `/api/sifts?` + new URLSearchParams(queryParams),
    {
      credentials: "include",
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        sift: {
          guid,
          collection: "catalogue",
          owner: "redsift",
          ...ALL_SIFTS[guid].sift,
        },
        ...(metadata && { metadata }),
        ...(tracking && { tracking }),
      }),
    }
  )
    .then((res) => res.json())
    .catch((err) => log.error("utils::installSift::err", err));

  if (found) {
    return { found };
  }
  if (awaitingProvisioning) {
    return { awaitingProvisioning };
  }
  if (!id) {
    log.error("utils::installSift:error");
    // TODO: more information to error
    return { error: "Failed to install sift" };
  }
  return { id };
};

export const getUtmParams = (search: string) => {
  const params: any = {};
  search
    .substring(1)
    .split("&")
    .forEach((param) => {
      const [key, value] = param.split("=");
      if (ANALYTICS_PARAMETERS[key]) {
        params[key] = value;
      }
    });
  if (Object.keys(params).length > 0) {
    return (
      "?" +
      Object.keys(params)
        .map((key) => `${key}=${params[key]}`)
        .join("&")
    );
  } else {
    return "";
  }
};

export const getProductParam = (search: string) => {
  const params = new URLSearchParams(search);
  return params.get("product");
};
